var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LaunchLibNext card my-4"},[_c('p',{staticClass:"card-header h5 text-uppercase text-left text-secondary"},[_vm._v("The Launch Library:")]),_c('h2',{staticClass:"h3 text-uppercase mx-auto text-center mt-4"},[_vm._v(" Upcoming Launches You Don't Want to Miss ")]),_c('div',{staticClass:"row my-2 card-body",attrs:{"id":"desktop"}},_vm._l((_vm.launches),function(launch,index){return _c('tablerowD',{key:index,attrs:{"mission":launch.mission && launch.mission.name != null
              ? launch.mission.name
              : launch.name,"image":launch.image != null || undefined ? launch.image : 'apple-icon.png',"rocket":launch.rocket.configuration.name,"launchsp":launch.launch_service_provider.name,"launchtime":launch.net != null ? launch.net : 'N/A',"pad":launch.pad.name != null ? launch.pad.name : 'N/A',"location":launch.pad.location && launch.pad.location.name != null
              ? launch.pad.location.name
              : 'N/A',"description":launch.mission && launch.mission.description != null
              ? launch.mission.description
              : 'This mission is classified or no overview is available at this time.'}})}),1),_c('div',{staticClass:"row my-3 card-body",attrs:{"id":"mobile"}},_vm._l((_vm.launches),function(launch,index){return _c('tablerowM',{key:index,attrs:{"mission":launch.mission && launch.mission.name != null
              ? launch.mission.name
              : launch.name,"image":launch.image != null || undefined ? launch.image : 'apple-icon.png',"rocket":launch.rocket.configuration.name,"launchsp":launch.launch_service_provider.name,"launchtime":launch.net != null ? launch.net : 'N/A',"pad":launch.pad.name != null ? launch.pad.name : 'N/A',"location":launch.pad.location && launch.pad.location.name != null
              ? launch.pad.location.name
              : 'N/A',"description":launch.mission && launch.mission.description != null
              ? launch.mission.description
              : 'This mission is classified or no overview is available at this time.'}})}),1),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('i',[_vm._v(" API Data brought to you by "),_c('a',{attrs:{"href":"https://thespacedevs.com","target":"_blank","rel":"noopener"}},[_vm._v("The Space Devs")])])])
}]

export { render, staticRenderFns }